var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Glow Badges" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeGlow) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use class")]),
        _c("code", [_vm._v(".badge-glow")]),
        _c("span", [_vm._v("to add glow effect with badges.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-badge",
            {
              staticClass: "badge-glow",
              attrs: { pill: "", variant: "primary" }
            },
            [_vm._v(" Primary ")]
          ),
          _c(
            "b-badge",
            {
              staticClass: "badge-glow",
              attrs: { pill: "", variant: "secondary" }
            },
            [_vm._v(" Secondary ")]
          ),
          _c(
            "b-badge",
            {
              staticClass: "badge-glow",
              attrs: { pill: "", variant: "success" }
            },
            [_vm._v(" Success ")]
          ),
          _c(
            "b-badge",
            {
              staticClass: "badge-glow",
              attrs: { pill: "", variant: "danger" }
            },
            [_vm._v(" Danger ")]
          ),
          _c(
            "b-badge",
            {
              staticClass: "badge-glow",
              attrs: { pill: "", variant: "warning" }
            },
            [_vm._v(" Warning ")]
          ),
          _c(
            "b-badge",
            { staticClass: "badge-glow", attrs: { pill: "", variant: "info" } },
            [_vm._v(" Info ")]
          ),
          _c(
            "b-badge",
            { staticClass: "badge-glow", attrs: { pill: "", variant: "dark" } },
            [_vm._v(" Dark ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }