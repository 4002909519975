var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pill-badge-contextual"),
      _c("pill-badge-glow"),
      _c("pill-badge-light"),
      _c("pill-badge-notification"),
      _c("pill-badge-link-block")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }