var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Pill Badges As Notification" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeNotification) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-" }, [
        _c("span", [_vm._v("Use class ")]),
        _c("code", [_vm._v("badge")]),
        _c("span", [_vm._v(" prop with ")]),
        _c("code", [_vm._v("<feather-icon>")]),
        _c("span", [_vm._v(" components to add badges as notification. Use ")]),
        _c("code", [_vm._v('badge-classes="badge-{color}"')]),
        _c("span", [_vm._v(" prop for change the badge color.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("feather-icon", {
            staticClass: "text-primary",
            attrs: { icon: "BellIcon", size: "21", badge: "4" }
          }),
          _c("feather-icon", {
            staticClass: "text-info",
            attrs: {
              icon: "BellIcon",
              size: "21",
              badge: "4",
              "badge-classes": "badge-info"
            }
          }),
          _c("feather-icon", {
            staticClass: "text-danger",
            attrs: {
              icon: "BellIcon",
              size: "21",
              badge: "4",
              "badge-classes": "badge-danger badge-glow"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }