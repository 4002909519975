var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Contextual" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePillContextual) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("pill")]),
        _c("span", [_vm._v(" prop to make badges more rounded")]),
        _c("span", [_vm._v(" and use ")]),
        _c("code", [_vm._v("variant")]),
        _c("span", [_vm._v(" prop for badge color variantion.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-badge", { attrs: { pill: "", variant: "primary" } }, [
            _vm._v(" Primary ")
          ]),
          _c("b-badge", { attrs: { pill: "" } }, [_vm._v(" Secondary ")]),
          _c("b-badge", { attrs: { pill: "", variant: "success" } }, [
            _vm._v(" Success ")
          ]),
          _c("b-badge", { attrs: { pill: "", variant: "danger" } }, [
            _vm._v(" Danger ")
          ]),
          _c("b-badge", { attrs: { pill: "", variant: "warning" } }, [
            _vm._v(" Warning ")
          ]),
          _c("b-badge", { attrs: { pill: "", variant: "info" } }, [
            _vm._v(" Info ")
          ]),
          _c("b-badge", { attrs: { pill: "", variant: "dark" } }, [
            _vm._v(" Dark ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }